import React, { useState } from "react"
import cx from "classnames"
import { Image } from "../image"
import { VideoModal } from "../video-modal"
import { RiPlayCircleLine } from "react-icons/ri"

import styles from "./video-grid.module.css"

export const VideoGrid = ({ videos }) => {
  const [activeVideoId, setActiveVideoId] = useState("")
  const [videoModalOpen, setVideoModalOpen] = useState(false)

  const toggleVideoModal = () => {
    setVideoModalOpen(!videoModalOpen)
  }

  const handleVideoButtonClick = videoId => {
    setActiveVideoId(videoId)
    toggleVideoModal()
  }

  return (
    <div className={styles.videoGrid}>
      <h2 className={cx("label", styles.gridTitle)}>Videos</h2>
      <div className={styles.grid}>
        {videos.edges.map(edge => {
          const { video, title, credits } = edge.node
          return (
            <div className={styles.gridItem} key={video.id}>
              <button
                onClick={() => handleVideoButtonClick(video.id)}
                className={cx("reset", styles.videoButton)}
              >
                <div className={styles.videoThumbnailContainer}>
                  <Image
                    className={styles.videoThumbnail}
                    src={`https://img.youtube.com/vi/${video.id}/0.jpg`}
                  />
                  <RiPlayCircleLine className={styles.playCircle} />
                </div>
                <div className={styles.overlay}>
                  <p className={cx("label", styles.videoTitle)}>{title}</p>
                </div>
              </button>
            </div>
          )
        })}
      </div>
      <VideoModal
        videoId={activeVideoId}
        open={videoModalOpen}
        toggle={toggleVideoModal}
      />
    </div>
  )
}
