import React from "react"
import { RevealOnMount } from "components/reveal-on-mount"
import SEO from "components/seo"
import { VideoGrid } from "components/video-grid"

const Videos = ({ location, pageContext }) => {
  const { videos } = pageContext
  return (
    <RevealOnMount location={location}>
      <SEO title="Videos" />
      <VideoGrid videos={videos} />
    </RevealOnMount>
  )
}

export default Videos
