import React from "react"
import { graphql } from "gatsby"
import Videos from "src/templates/videos"

export const query = graphql`
  query VideosQuery {
    videos: allSanityVideo(sort: { fields: order }) {
      edges {
        node {
          title
          credits: _rawCredits
          video: _rawVideo
          order
        }
      }
    }
  }
`

const VideoPage = ({ data, location }) => {
  return <Videos location={location} pageContext={data} />
}

export default VideoPage
